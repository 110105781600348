import { CandidateCommand } from "@/data";
import { DropdownCollection } from "@/dsComponents/Table/types";
import { DropdownItemColors } from "@/dsComponents/Dropdown/types";
import { IJobOffersWorker } from "@/types";
import { ref } from "vue";
import { CandidateActionsModalsNames } from "@/data";
import store from "@/store";

interface CandidateCommands {
    actions?: CandidateCommand[];
    contact?: CandidateCommand[];
}

interface CandidateStatusActionsForDropdown extends DropdownCollection {
    supervisorAccess?: boolean;
    candidateCommand?: CandidateCommand;
    groupName: keyof CandidateCommands;
}

export default function useCandidateStatusActions(candidateAvailableCommands: CandidateCommands, onlyItems: boolean) {
    const modalToOpen = ref<CandidateActionsModalsNames>();
    const actionToExecute = ref<CandidateCommand>();
    const isSupervisor = store.getters.isSupervisor;

    const candidateStatusActions: CandidateStatusActionsForDropdown[] = [
        {
            type: "title",
            title: { icon: "click", label: "Actions" },
            groupName: "actions",
        },
        {
            type: "item",
            candidateCommand: CandidateCommand.interested,
            groupName: "actions",
            item: {
                iconLeft: "phone",
                label: "Marquer à contacter",
                iconLeftColor: DropdownItemColors.grey,
                onClick: (item: IJobOffersWorker) => {
                    modalToOpen.value = CandidateActionsModalsNames.TO_MEET_MODAL;
                },
            },
            supervisorAccess: false,
        },
        {
            type: "item",
            candidateCommand: CandidateCommand.scheduleMeeting,
            groupName: "actions",
            item: {
                iconLeft: "calendar-time",
                label: "Planifier un entretien",
                iconLeftColor: DropdownItemColors.grey,
                onClick: (item: IJobOffersWorker) => {
                    modalToOpen.value = CandidateActionsModalsNames.DATE_SCHEDULING_MODAL;
                },
            },
            supervisorAccess: false,
        },
        {
            type: "item",
            candidateCommand: CandidateCommand.noteAgency,
            groupName: "actions",
            item: {
                iconLeft: "pencil",
                label: "Retour d'entretien téléphonique",
                iconLeftColor: DropdownItemColors.grey,
                onClick: (item: IJobOffersWorker) => {
                    modalToOpen.value = CandidateActionsModalsNames.NOTE_AGENCY_MODAL;
                },
            },
            supervisorAccess: false,
        },
        {
            type: "item",
            candidateCommand: CandidateCommand.meetingResult,
            groupName: "actions",
            item: {
                iconLeft: "note",
                label: "Enregister retour d'entretien",
                iconLeftColor: DropdownItemColors.grey,
                onClick: (item: IJobOffersWorker) => {
                    modalToOpen.value = CandidateActionsModalsNames.AFTER_MEETING_MODAL;
                },
            },
            supervisorAccess: false,
        },
        {
            type: "item",
            candidateCommand: CandidateCommand.integrate,
            groupName: "actions",
            item: {
                iconLeft: "users-group",
                label: "Intégrer au vivier",
                iconLeftColor: DropdownItemColors.grey,
                onClick: (item: IJobOffersWorker) => {
                    modalToOpen.value = CandidateActionsModalsNames.INTEGRATION_MODAL;
                },
            },
            supervisorAccess: false,
        },
        {
            type: "item",
            candidateCommand: CandidateCommand.reject,
            groupName: "actions",
            item: {
                iconLeft: "user-cancel",
                label: "Refuser",
                iconLeftColor: DropdownItemColors.red,
                onClick: (item: IJobOffersWorker) => {
                    modalToOpen.value = CandidateActionsModalsNames.REJECTION_MODAL;
                },
            },
            supervisorAccess: false,
        },
        // ---- CONTACT ----
        {
            type: "title",
            title: { icon: "message-circle", label: "Actions" },
            groupName: "contact",
        },
        {
            type: "item",
            candidateCommand: CandidateCommand.voiceMessage,
            groupName: "contact",
            item: {
                iconLeft: "record-mail",
                label: "Message vocal laissé",
                iconLeftColor: DropdownItemColors.grey,
                onClick: (item: IJobOffersWorker) => {
                    actionToExecute.value = CandidateCommand.voiceMessage;
                },
            },
            supervisorAccess: false,
        },
        {
            type: "item",
            candidateCommand: CandidateCommand.contact,
            groupName: "contact",
            item: {
                iconLeft: "phone-check",
                label: "Marquer comme appelé",
                iconLeftColor: DropdownItemColors.grey,
                onClick: (item: IJobOffersWorker) => {
                    actionToExecute.value = CandidateCommand.contact;
                },
            },
            supervisorAccess: false,
        },
    ];

    const filteredActions = candidateStatusActions.filter((action) => {
        const commandAvailable = Object.values(candidateAvailableCommands).flat().includes(action.candidateCommand);
        if (action.type === "title" && !onlyItems) {
            return candidateAvailableCommands[action.groupName] ?? false;
        }
        return commandAvailable;
    });

    const getCandidateFilteredActions = (commands: CandidateCommands) => {
        return candidateStatusActions.filter((action) => {
            if (!action.supervisorAccess && isSupervisor) return false;
            const commandAvailable = Object.values(candidateAvailableCommands).flat().includes(action.candidateCommand);
            if (action.type === "title" && !onlyItems) {
                return candidateAvailableCommands[action.groupName] ?? false;
            }
            return commandAvailable;
        });
    };

    return { filteredActions, modalToOpen, actionToExecute, getCandidateFilteredActions };
}
